@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
@import 'flatpickr/dist/flatpickr.css';

.payment-method-details-parent {
  max-height: 450px;
  overflow: auto;
  overflow-y: scroll;
  padding: 15px;
  padding-top: 0px;
  margin-bottom: 10px;
  .payment-method-details-child {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 10px;

    .payment-method-details-child-odd {
      color: #666;
    }
    .payment-method-details-child-even {
      color: #0e3e46;
    }
  }
}
.payment-method-details-parent::-webkit-scrollbar {
  width: 3px;
}
.payment-method-details-parent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #cfe9ee;
}

.close-btn-payment-method {
  margin: 0px !important;
}

.main_action {
  min-width: 100px;
  margin: 0.5rem;
}

.main_tab {
  font-size: 0.9rem;
  margin-right: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: bold;
}

.not-allowed {
  cursor: not-allowed !important;
}

.green {
  color: var(--color-success);
}

.red {
  color: var(--color-danger);
}

.approved-btn,
.rejected-btn {
  transition: all 0.2s;
}

.approved-btn:hover {
  color: var(--color-success);
}

.rejected-btn:hover {
  color: var(--color-danger);
}

.editable:hover {
  background-color: var(--color-table-hover-bg);
  cursor: pointer;
}

.table-footer {
  background-color: var(--color-secondary);
}

.password-show-hide {
  cursor: pointer;
}

.card_title {
  font-size: 20px;
  line-height: 26px;
  color: #00185f;
}

.disable {
  opacity: 0.4;
}

.see-all-btn {
  color: var(--color-primary) !important;
  font-weight: bold;
  font-size: 14px;
  outline: none;
}

.settings-card-item {
  margin: 20px 0;
  border-radius: 15px;
  padding: 8px 10px;
  box-shadow: var(--color-box-shadow-default);
}

.settings-card-item-wrapper {
  padding: 0 5%;

  h5 {
    font-size: 16px;
  }
}

.settings-widget {
  height: 470px;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-wraper-yellow {
  color: #ffc700;
  box-shadow: 0px 0px 10px rgba(255, 199, 0, 0.6);
}

.icon-wraper-red {
  color: var(--color-red);
  box-shadow: 0px 0px 10px rgba(255, 0, 0, 0.6);
}

.icon-wraper-green {
  color: var(--color-success);
  box-shadow: 0px 0px 10px rgba(29, 159, 8, 0.6);

  &:hover {
    color: white;
    background-color: var(--color-success);
    cursor: pointer;
  }
}

.client-profile-widget {
  min-height: 400px;
  height: calc(100% - 30px);
}

.client-profile-card-form-wrapper {
  margin-top: 40px;
  //padding: 0 25% 0 5%;

  h5 {
    font-size: 16px;
  }
}
.drag-box {
  height: 100%;
}

.client-profile-card-item-wrapper {
  margin-top: 40px;
  //padding: 0 5%;

  h5 {
    font-size: 16px;
  }
}

.client-profile-card-item {
  margin: 24px 0;
  border-radius: 15px;
  padding: 8px 10px;
  box-shadow: var(--color-box-shadow-default);
}

.icon-wraper {
  padding: 6px;
  border-radius: 50%;
  font-size: 20px;
  width: 34px;
  justify-content: center;
  height: 34px;
  display: flex;
}

.property_checkbox {
  margin: 36px 5px;
}

table {
  width: 100%;
}

tr.mat-row {
  height: 35px !important;
}

.mat-cell > div.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: inherit;
}
*, *::before, *::after {
    box-sizing: border-box;
}
user agent stylesheet
div {
    display: block;
}

.mat-mdc-table td {
    font-size: 12px !important;
    color: #4b4b4b !important;
}

tr.mdc-data-table__row {
  height: 35px !important;
}
td.mdc-data-table__cell {
  padding: 0 12px;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0 3px 0 10px;
}

.date-side {

  :host .mat-focused .mat-form-field-label {
    color: #3f6ad8 !important;
  }
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 14px;
  }

  .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-infix {
    width: 180px !important;
  }
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    margin-top: 12px;
  }
  .mat-mdc-icon-button svg {
    width: 14px;
    height: 14px;
    margin-top: 8px;
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    position: absolute;
    top: 50%;
    height: 21px;
    left: 50%;
    width: 21px;
    transform: translate(-50%, -50%);
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 18px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mdc-text-field {
    position:  relative !important;
  }

  .mdc-text-field .mdc-text-field__input {
    margin-bottom: 3px;
  }
}

.card-header {
  padding-left: 24px;
  padding-right: 10px;
  gap: 24px 0;
}

.card-body {
  padding: 24px 24px;
}

body {
  background-color: #f7f7f8;
}

.mat-mdc-form-field-flex * {
  border: 0 !important;
}

.mat-mdc-form-field {
  position: relative;
}

.mat-mdc-form-field-infix {
  padding-top: 0;
  width: 80px !important;
}





.mdc-text-field {
  // Have an issues with this position inherit in other components
  position: inherit !important;
  will-change: auto !important;
}
.mat-mdc-form-field-infix {
  min-height: 0px !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 0px !important;
}
.mat-mdc-select-arrow {
  color: cadetblue;
}
.mat-mdc-select-min-line {
  font-size: initial !important;
 }
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white;
}
.mdc-text-field--focused {
  background-color: white ;
}
.mat-mdc-icon-button svg {
  transform: translateY(-3px);
}
.table-wrapper {
  overflow-x: auto;
  height: calc(100vh - 360px);
}

.history .mat-mdc-text-field-wrapper,
.Drawer .mat-mdc-text-field-wrapper
{
  margin-top: 8px;
}

.history .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
// .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
// .mat-mdc-form-field-infix {
//   padding-top: 4px;
//   padding-bottom: 4px;
//   min-height: 32px;
// }

.mat-table {
  overflow-x: auto;
}

.mat-mdc-header-cell, .mat-mdc-cell {
  word-wrap: initial;
  display: table-cell;
  padding: 3px 10px !important;
  line-break: unset;
  white-space: nowrap;
  // overflow: hidden;
  vertical-align: middle;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}

.mat-mdc-header-cell > div.ellipsis,
.mat-mdc-cell > div.ellipsis
 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: inherit;
}

// tbody .mat-cell:hover {
//   border-right: 1px solid var(--color-table-hover-bg);
//   border-left: 1px solid var(--color-table-hover-bg);
// }

.mat-header-cell {
  word-wrap: initial;
  display: table-cell;
  padding: 3px 10px !important;
  line-break: unset;
  white-space: nowrap;
  // overflow: hidden;
  vertical-align: middle;
}

.mat-row,
.mat-header-row {
  display: table-row;
}

.psp-delete {
  cursor: pointer;
  margin-top: 35px;
  border: 1px solid var(--color-primary);
  background: #fff;
  color: var(--color-primary);
  border-radius: 10px;
  padding: 5px 8.5px 3px;
}

.no-data {
  color: #acb2b7;
  text-align: center;
}

.status-online {
  display: inline-block;
  background-color: #0bb512;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-left: 2px;
}

.status-label {
  padding: 3px 0;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.widget-zebra-list {
  .z-item:nth-child(odd) {
    background: #f2f3f5;
  }
}

.mat-form-field-wrap {
  max-width: 130px;
  margin: 0 10px;
  display: inline;
}

ngx-dropzone-label {
  width: 100%;
}

.comment-textarea {
  width: 100%;
  position: relative;

  i {
    position: absolute;
    top: 6px;
    right: 20px;
    transform: rotate(45deg);
    color: var(--color-primary);
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1000;
  }

  textarea {
    padding-right: 40px;
    height: 31px;
  }

  .invalid {
    border-color: var(--color-danger);
  }

  mwlResizable {
    box-sizing: border-box;
  }
}

.selected-row {
  background-color: var(--color-table-selected-row) !important;
}

/***************** Leader Board Pop Up custom styles *****************/

.deposit-popup {
  align-items: center !important;
  background-position: center !important;
  background-size: cover !important;

  .swal2-header {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 16px;

    .swal2-image {
      margin: 0.7em auto;
    }

    .swal2-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 0;
      color: var(--color-body-color);
    }
  }

  .swal2-content {
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 0;
    color: var(--color-body-color);
    padding-bottom: 16px;
  }

  .swal2-footer {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #02c550;
    padding: 0;
    margin: 14px 0;
  }
}

/***************** Trading Platform Colors*****************/

.mt4 {
  border-bottom: 2px solid var(--color-mt4);
  width: fit-content;
}

.mt5 {
  border-bottom: 2px solid var(--color-mt5);
  width: fit-content;
}

.mt4-demo {
  border-bottom: 2px solid var(--color-mt4);
  display: inline-block;
}

.mt5-demo {
  border-bottom: 2px solid var(--color-mt5);
  display: inline-block;
}

/***************** Drag box *****************/

.drag-box .card_title {
  padding-left: 32px !important;
}

.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb,
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch:enabled .mdc-switch__track:after,
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after,
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch:enabled:active .mdc-switch__track::after,
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after {
  background-color: rgba(34, 155, 174, 0.4);

}

.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar,
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle:after,
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after,
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__handle::after,
.mat-mdc-slide-toggle .mdc-form-field .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after {
  background-color: var(--color-primary)
}
.mat-mdc-slide-toggle .mdc-form-field label {
  margin-bottom: 0;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  background: var(--color-light) !important;
  border-color: var(--color-light) !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: var(--color-primary)
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  color: var(--color-light);
}

.pe-7s-check {
  color: var(--color-light) !important;
}

ngb-datepicker > .ngb-dp-months {
  display: block !important;
}

.all-user-table {
  .mat-cell {
    width: 200px !important;
  }
}

.mat-cell {
  .btn-link {
    color: var(--color-primary);
  }

  .btn-link:disabled,
  .btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
  }
}

//------------------- radio buttons ------------------

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--color-primary);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color-primary);
}

//------------------- makes svg to atoll color ------------------

.filter-to-atoll {
  filter: brightness(0) saturate(100%) invert(39%) sepia(80%) saturate(409%)
    hue-rotate(140deg) brightness(88%) contrast(95%);
}

//------------------- buttons in filter section ------------------

.btn-outline-light {
  color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;

  &:hover {
    color: #fff !important;
    background: var(--color-primary);
  }

  &[aria-expanded='true'] {
    color: #fff !important;
  }
}

#live-data {
  &:hover {
    background-color: var(--color-primary) !important;
    color: white !important;
  }
}

//------------------- aggrid search buttons and icons ------------------

.ag-theme-alpine .ag-standard-button {
  color: var(--color-primary) !important;
  border-color: var(--color-primary);

  &:hover {
    background: var(--color-primary) !important;
    color: #fff !important;
    border-color: var(--color-primary);
  }
}

span.ag-icon.ag-icon-filter,
.ag-header-cell-menu-button,
.ag-side-button-button {
  &:hover {
    filter: brightness(0) saturate(100%) invert(39%) sepia(80%) saturate(409%)
      hue-rotate(140deg) brightness(88%) contrast(95%) !important;
  }
}

.ag-side-bar-right .ag-selected .ag-side-button-button {
  border-left-color: var(--color-primary) !important;
}

.mat-expansion-panel-body {
  padding-bottom: 120px !important;
}

.mat-accordion {
  .mat-expansion-panel {
    margin-bottom: 10px;
  }
}

/*********** CKEdotor *************/

#cke_1_contents {
  min-height: 550px !important;
}

/*********** Email-template's select *************/

.language {
  .mat-form-field-infix {
    width: 43px !important;
    border: 0;
  }

  .mat-select-arrow {
    border: 0;
  }

  .mat-select-arrow-wrapper::after {
    content: url('../../crm/src/assets/images/icons/down-small.svg');
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding: 0;
  }
}

// -------------- Onboarding elements --------------

.onboarding-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f18017;
  border-radius: 50%;
  position: relative;
  left: 5px;
  top: -5px;
}

.onboarding-badge {
  background: #f18017;
  border-radius: 24px;
  color: white;
  padding: 1px 6px 2px;
  font-weight: 700;
  font-size: 10px;
  position: relative;
}

.onboarding-card {
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  width: 216px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(116, 118, 120, 0.25);

  .picture {
    height: 128px;
    // background-image: url('assets/images/Onboarding-guy.png');
  }

  .text-block {
    padding: 16px 10px;

    h6 {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #4e4e4e;
    }

    p {
      font-size: 12px;
      line-height: 14px;
      color: #666;
      margin-bottom: 8px;
    }

    span {
      font-weight: 600;
      font-size: 12px;
      cursor: pointer;
      color: var(--color-primary);
    }
  }
}

// -------- Withdrawals confirmation popup --------

.confirm,
.cancel {
  width: 137px !important;
  height: 36px !important;
  border: 2px solid var(--color-primary) !important;
  border-radius: 10px !important;
  background-color: transparent !important;
  padding: 8px 16px !important;
  color: var(--color-primary) !important;
  font-size: 14px !important;
  line-height: 16px !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.cancel {
  border-color: var(--color-danger) !important;
  color: var(--color-danger) !important;
}

.confirm-title {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 23px !important;
  color: var(--color-primary) !important;
}

.confirm-text {
  font-size: 16px !important;
  line-height: 28px !important;
  color: var(--color-primary) !important;
  margin-bottom: 50px !important;
}

.dashboard-widget {
  min-height: 400px;
}

// ----------- Dashboard Misc Chart ----------------

.chart-legend {
  .legend-labels {
    margin-left: 10px !important;
  }

  .legend-label {
    color: #4d4f53 !important;
    margin-right: 3% !important;
  }

  .legend-label-color,
  .legend-label-text {
    display: block !important;
    overflow: visible !important;
  }

  .legend-label-color {
    width: 14px !important;
    height: 14px !important;
    border-radius: 50% !important;
    margin: 0 0 0 calc(50% - 7px) !important;
  }

  .legend-label-text {
    font-size: 13px !important;
    margin-top: 5px !important;
    font-weight: 400 !important;
  }
}

.confirmAletUiConfig {
  display: flex;
  color: var(--color-primary) !important;
}

// --------------- Styling pop-up with an input field ---------------

.doc-details-input-popup {
  padding: 40px !important;
  border-radius: 24px !important;
}

.doc-details-input-popup-title {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  color: #071f23 !important;
  margin-bottom: 50px !important;
}

.doc-details-input-popup-content {
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #071f23 !important;
}

.doc-details-input-popup-input {
  border-radius: 10px !important;
  border: 1px solid #ced4da !important;
  padding: 13px !important;
  color: #626d78 !important;
  font-size: 12px !important;
  height: 40px !important;

  &:focus {
    border-color: var(--color-primary) !important;
    color: #071f23 !important;
  }
}

.doc-details-input-popup-actions {
  justify-content: end !important;
  flex-direction: row-reverse !important;
}

.doc-details-input-popup-confirm-button,
.doc-details-input-popup-cancel-button {
  border-radius: 10px !important;
  padding: 10px 32px !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 15px !important;
  text-align: center !important;
  margin: 5px 0 5px 13px !important;

  &:hover {
    box-shadow: 0 0 3px var(--color-light) !important;
  }
}

.doc-details-input-popup-confirm-button {
  background: var(--color-light) !important;
  border: 2px solid var(--color-light) !important;
  color: white !important;
}

.doc-details-input-popup-cancel-button {
  background: white !important;
  border: 2px solid var(--color-light) !important;
  color: var(--color-light) !important;
}

.doc-details-input-popup-close-button {
  top: 10px !important;
  right: 10px !important;
  font-size: 30px !important;
}

// --------------- Styling pop-up with an input field finished ---------------

// --------------- Styling Success pop-up ---------------

.success-popup {
  border-radius: 24px !important;
  width: 500px !important;
  height: 350px !important;
  padding: 50px !important;
}

// --------------- Styling Success pop-up finished---------------

// ----------------------------- Delete pop-up -------------------------------

.delete-popup {
  padding: 40px 35px !important;
  border-radius: 8px !important;
  width: 446px !important;
  height: 253px !important;
}

.delete-popup-title {
  font-size: 20px !important;
  line-height: 23px !important;
  margin-bottom: 15px !important;
}

.delete-popup-content {
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19px !important;
  color: #666 !important;
  margin-bottom: 45px !important;
}

.delete-popup-actions {
  flex-direction: row-reverse !important;
}

.delete-popup-cancel-button,
.delete-popup-confirm-button {
  box-sizing: border-box !important;
  width: 110px !important;
  height: 35px !important;
  text-align: center !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  padding-top: 6px !important;
  border-radius: 10px !important;
}

.delete-popup-cancel-button {
  background: white !important;
  border: 2px solid var(--color-primary) !important;
  color: var(--color-primary) !important;
  margin-right: 15px !important;
}

.delete-popup-confirm-button {
  background: var(--color-primary) !important;
  border: 2px solid var(--color-primary) !important;
  color: white !important;
  margin-left: 15px !important;
}

// ----------------------------- Delete pop-up finished-------------------------------

// can be used globally
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
