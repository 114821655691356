// General

a,
button,
.btn {
  outline: none !important;
}

.app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
}

.icon-anim-pulse {
  animation: pulse_animation;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulse_animation {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1.21);
  }
  50% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.09);
  }
  80% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

ngb-rating:focus {
  outline: none !important;
}
