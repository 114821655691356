// Material Modifiers

.mat-radio-label {
  margin-bottom: 0;
}

.mat-chip {
  line-height: 1;
}

.page-mat-tabs {
  .mat-tab-header {
    margin: (-($grid-gutter-width)) (-($grid-gutter-width)) ($grid-gutter-width);
  }
}

.mat-table {
  th {
    font-weight: bold !important;
    font-size: 14px !important;
    color: $text !important;
  }
  td {
    font-size: 12px !important;
    color: $text !important;
  }
}

.mat-slide-toggle-label-before .mat-slide-toggle-bar {
  margin-left: 15px !important;
}
