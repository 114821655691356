ngb-datepicker {
  option {
    background: #4ac8dc;
    color: white;
  }

  .form-select::-webkit-scrollbar {
    width: 3px;
  }

  .form-select::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b5e3ec;
  }

  .form-select {
    cursor: pointer;
    background-color: #f8f9fa;
    border: 2px solid #ced4da;
    border-radius: 0.75rem;
    margin-right: 5px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
    appearance: none;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  }

  .form-select:focus {
    border-color: #4ac8dc;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 0 0.2rem rgba(74, 200, 220, .5);
  }

  ngb-datepicker-navigation-select {
    select {
      background-color: white !important;
    }

    .ngb-datepicker-navigation-select>.custom-select {
      flex: 1 1 auto;
      font-size: .875rem;
      height: 1.85rem;
      padding: 0 0.5rem;
    }
  }

  .ngb-dp-header {
    background-color: #f8f9fa;
    background-color: var(--light);
    border-bottom: 0;
    border-radius: 0.25rem 0.25rem 0 0;
    padding-top: 0.25rem;
  }

  .ngb-datepicker-navigation-select>.custom-select {
    flex: 1 1 auto;
    font-size: .875rem;
    height: 1.85rem;
    padding: 0 0.5rem;
  }

  .ngb-dp-weekdays {
    background-color: #f8f9fa;
    background-color: var(--light);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0;
  }

  .ngb-dp-day {
    cursor: pointer;

    [ngbDatepickerDayView].outside {
      opacity: .5 !important;
    }

    .text-muted {
      color: rgb(32, 31, 31) !important;
    }
  }

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    height: 2rem;
    width: 2rem;
    color: #dfdcdc;
  }

  .ngb-dp-day:hover {
    background-color: #1b7c8b;
    color: white;
    border-radius: 5px !important;

  }

  .ngb-dp-day.disabled,
  .ngb-dp-day.hidden {
    cursor: default !important;
    pointer-events: none !important;
  }

}