// Input groups

.input-group {
  .input-group-prepend {
    div:not([class]) .react-datepicker__input-container,
    & + div .react-datepicker__input-container {
      .form-control {
        @include border-left-radius(0);
        @include border-right-radius($input-border-radius !important);
      }
    }
  }

  & > div:not([class]) {
    position: relative;
    flex: 1 1 auto;
    width: 1%;

    & .react-datepicker__input-container {
      .form-control {
        @include border-right-radius(0);
      }
    }
  }
}

.input-group {
  .input-group-prepend {
    & + div {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
    }
  }

  & > .react-datepicker-wrapper {
    position: relative;
    flex: 1 1 auto;
    width: 1%;

    & > .react-datepicker__input-container > .form-control {
      @include border-right-radius(0);
    }
  }
}

// Forms Legend

legend {
  font-size: $font-size-base;
  font-weight: bold;
}

// Headings

.form-heading {
  font-size: $font-size-lg;
  margin: 0;
  color: $primary;

  p {
    color: $gray-600;
    padding: 0.3rem 0 0;
    font-size: $font-size-base;
  }
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form-group {
  label {
    font-weight: bold !important;
    font-size: 14px !important;
    color: $text !important;
    margin-bottom: 10px;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 13px !important;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 13px !important;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    font-size: 13px !important;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    font-size: 13px !important;
  }
}
