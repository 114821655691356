// Main Base

.app-main__outer {
  flex: 1;
  flex-direction: column;
  display: flex;
  z-index: 8;
  padding: $app-header-height 0 0 $app-sidebar-width;
  transition: all 0.2s;
}

.app-main__inner {
  padding: $grid-gutter-width $grid-gutter-width 0;
  flex: 1;
}

.body-tabs {
  margin: (- calc($grid-gutter-width / 3)) 0 calc($grid-gutter-width / 1.3);
}

// Themes

@import 'themes/main-white';
