// Sidebar Light

.app-sidebar {
  &.text-lighter {
    .app-sidebar-content {
      background: none !important;
    }

    .app-header__logo {
      background: rgba(255, 255, 255, 0.1) !important;
    }

    .logo-src {
      background: url('/assets/images/logo.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background: rgba(255, 255, 255, 0.8) !important;
    }

    .hamburger-box:hover {
      .hamburger-inner,
      .hamburger-inner::before,
      .hamburger-inner::after {
        background: rgba(255, 255, 255, 1) !important;
      }
    }

    .v-sidebar-menu {
      .vsm-header {
        color: rgba(27, 124, 139, 1);

        &::before {
          background: rgba(255, 255, 255, 0.4);
        }
      }

      %side-link-hover {
        background: #e4f3f6;
        // color: rgba(255, 255, 255, 1);
      }

      & .vsm-item.active-item {
        & > .vsm-link {
          @extend %side-link-hover;
        }
      }

      .vsm-arrow,
      .vsm-icon {
        opacity: 1;
      }

      & .vsm-link {
        color: rgba(27, 124, 139, 1);
        font-size: 14px;
        &:hover {
          .vsm-icon {
            opacity: 0.9;
          }
        }
      }

      & .vsm-dropdown {
        &::before {
          background: #e4f3f6;
        }
      }
    }

    perfect-scrollbar > .ps.ps--scrolling-y > .ps__rail-y,
    .ps .ps__rail-y:hover {
      background-color: rgba(255, 255, 255, 0.15) !important;
    }

    .ps__thumb-y {
      background-color: rgba(255, 255, 255, 0.3) !important;
    }
  }
}
.closed-sidebar .app-sidebar .logo-src {
  background-image: url('/assets/images/gamba.svg');
}
